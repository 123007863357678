<template>
  <div class="order-detail p20 bsbb">
    <van-nav-bar title="訂單詳情" left-arrow :border="false" @click-left="onClickLeft" />
    <div class="address mt20 df fdc p20 bsbb">
      <div class="df aic jcsb mb10" @click="$router.push('/user/address')" style="position: relative;">
        <p class="fz32" style="color: forestgreen">選擇收貨地址 <span
            style="color: black;margin-left: 10px;font-size: 14px;overflow: hidden;
                                                                                                                                                      display: -webkit-box;
                                                                                                                                                      -webkit-box-orient: vertical;
                                                                                                                                                      -webkit-line-clamp: 1;
                                                                                                                                                      position: absolute;
                                                                                                                                                      top: 0px;
                                                                                                                                                      left: 45%;
                                                                                                                                                      width: 50%;">{{
                                                                                                                                                        addgrs }}</span>
        </p>
        <van-icon name="arrow" class="fz16" />
      </div>

    </div>
    <div class="content mt20 p20 bsbb">
      <div class="fz30 fw7 mb40">{{ this.$route.query.name }}</div>
      <div class="items">
        <div v-for="(v, i) in goodsList" :key="i" class="item df aic jcsb">
          <div class="df">
            <img class="mr20" :src="v.shop_home_img" style="width: 60px; border-radius: 10px" />
            <p class="fz24 fw7" style="width: 200px">
              {{ v.trade_name }}
            </p>
          </div>
          <div class="df fdc" style="text-align: right">
            <p class="fz24">{{ v.trade_price }}</p>

          </div>
        </div>
        <!-- <div class="item df aic jcsb">
          <div class="df">
            <img
              class="mr20"
              src="../../assets/images/xg/hotshop2.jpg"
              style="width: 60px; border-radius: 10px"
            />
            <p class="fz24 fw7" style="width: 200px">
              【新鲜到货】智利进进口4J车厘子100g
            </p>
          </div>
          <div class="df fdc" style="text-align: right">
            <p class="fz24">实付49.8</p>
            <p class="fz16 thr">69.6</p>
          </div>
        </div> -->
        <!-- <div class="item df aic jcsb">
          <div class="df">
            <img
              class="mr20"
              src="../../assets/images/xg/hotshop1.jpg"
              style="width: 60px; border-radius: 10px"
            />
            <p class="fz24 fw7" style="width: 200px">
              【新鲜到货】智利进进口4J车厘子100g
            </p>
          </div>
          <div class="df fdc" style="text-align: right">
            <p class="fz24">实付49.8</p>
            <p class="fz16 thr">69.6</p>
          </div>
        </div> -->
      </div>
      <div class="other df fdc fz16 mt40">


        <div class="df aic jcsb mb20">
          <p>支付方式</p>
          <p @click="ways">{{ way }} <van-icon name="arrow" class="fz16" /></p>
        </div>
      </div>
      <div class="rever fz16 df aic jcsb">
        <div class="df aic">

          <p class="fz28 fw7">{{ totalPrices }}</p>
          <van-icon name="arrow" />
        </div>
      </div>
    </div>
  <!--  <div class="phone df fdc fz16 mt20 p20 bsbb">
      <div class="df aic jcsb">
        <div class="df aic">
          <img src="../../assets/images/xg/dun.png" style="width: 20px" />
          <p class="fz26 fw7">号码保护</p>
        </div>
        <div class="df aic">
          <p class="mr20">隐私保护中</p>
          <van-checkbox v-model="checked" checked-color="#79ce6b" icon-size="16px" />
        </div>
      </div>
      <div class="test mt20">
        <p v-if="checked == true">
          为保护您的隐私安全，已对商家，骑手隐藏您的真实手机号为保障服务质量，开启号码保护的订单通话可能会被录音
        </p>
        <p v-else>为保护您的隐私安全，已对商家、骑手隐藏您的真实手机号</p>
      </div>
    </div>
    <div class="dec bsbb mt20 fz16 df fdc fw5" style="color: #000">
      <div class="df aic jcsb mb20">
        <p>备注</p>
        <p style="color: #999">推荐使用无接触配送</p>
      </div>
      <div class="df aic jcsb mb20">
        <p>如遇缺货</p>
        <p>缺货时与我沟通</p>
      </div>
    </div> -->
    <div class="in" />
    <footer class="df aic bsbb jcsb">
      <div class="left df fdc bsbb">
        <p style="color: #fff" class="fz36 mt10">{{ totalPrices }}</p>
       <!-- <p class="fz14" style="color: #666">已优惠¥24.4</p> -->
      </div>
      <div class="right df aic jcc fz40" @click="submitOrder">去付款</div>
     <!-- <van-popup round class="p10 bsbb df fdc aic jcc" v-model:show="show2" style="width:90%">
        <p class="fz30 fw7 mb20">确认支付密码</p>
        <input class="fz20"
          style="width:90%;height:40px;background: #ededed;border:none;text-indent: 10px;line-height:40px"
          type="password" placeholder="请输入您的支付密码" v-model="payPassword">
        <div class="btns df aic jcsb mt20" style="width:80%">
          <button class="fz18" style="width:120px;height:40px;border:none;" @click="show2 = false">取消</button>
          <button class="fz18" style="width:120px;height:40px;border:none;" @click="confirmPa">确认</button>
        </div>
      </van-popup> -->
    </footer>
  </div>
</template>

<script>
  import {
    Toast,
    Popup
  } from "vant";
  import {
    orderPay,
    listShipAddress,
    checkPayPwdIsCorrect
  } from "../../utils/api";
  import {
    mapGetters,
    mapMutations
  } from "vuex";
  export default {
    data() {
      return {
        page: 1,
        page_size: 10,
        goodsList: [],
        order_no: "",
        checked: true,
        show: false,
        order_no: this.$route.query.order_no,
        totalPrices: this.$route.query.buy_actual_amount,
        activeId: 1,
        activeIndex: 0,
        nav: 0,
        time: "大约17:54送达",
        day: "立即送出",
        timing: "",
        orderList: JSON.parse(localStorage.getItem("orderList")),
        way: JSON.parse(localStorage.getItem("wayts")),
        divfrom: '',
        items: [{
            text: "今天",
            children: [{
                text: "立即送出(4.0元配送费)",
                id: 1
              },
              {
                text: "19:00(4.0元配送费)",
                id: 2
              },
              {
                text: "20:00(4.0元配送费)",
                id: 3
              },
              {
                text: "21:00(4.0元配送费)",
                id: 4
              },
            ],
          },
          {
            text: "明天",
            children: [{
                text: "立即送出(4.0元配送费)",
                id: 5
              },
              {
                text: "19:00(4.0元配送费)",
                id: 6
              },
              {
                text: "20:00(4.0元配送费)",
                id: 7
              },
              {
                text: "21:00(4.0元配送费)",
                id: 8
              },
            ],
          },
          {
            text: "2月4号(周六)",
            children: [{
                text: "立即送出(4.0元配送费)",
                id: 9
              },
              {
                text: "19:00(4.0元配送费)",
                id: 10
              },
              {
                text: "20:00(4.0元配送费)",
                id: 11
              },
              {
                text: "21:00(4.0元配送费)",
                id: 12
              },
            ],
          },
        ],
        addgrs: '',
        list: [],
        show2: false,
        payPassword: '',
        address_id: localStorage.getItem('addressId')
      };
    },
    computed: {
      ...mapGetters("goods", ["goodsShopList", "getMerchantList"]),
      isSelect: {
        get() {
          return this.orderList.reduce((total, cur) => {
            return total + cur.num;
          }, 0);
        },
      },
      totalPrice: {
        get() {
          let all = 0;
          all = this.orderList.reduce((total, cur) => {
            return total + cur.trade_price * cur.num;
          }, 0);
          return all.toFixed(2);
        },
      },
      isNav: {
        get() {
          if (this.nav == 0) {
            return (this.day = "今天");
          } else if (this.nav == 1) {
            return (this.day = "明天");
          } else if (this.nav == 2) {
            return (this.day = "2月4号");
          }
        },
      },
    },
    created() {
      this.getMhList()
      if (this.$route.query.judge == '/home') {
        this.goodsList = JSON.parse(localStorage.getItem("orderList"))
      } else {
        this.goodsList = this.getMerchantList
      }
      if (!JSON.parse(localStorage.getItem("wayts"))) {
        this.way = 'USDT'
      } else if (this.way == 1) {
        this.way = 'USDT'
      } else if (this.way == 3) {
        this.way = 'ROC'
      } else if (this.way == 4) {
        this.way = '消费卷'
      } else if (this.way == 5) {
        this.way = '充值积分+消费积分'
      } else if (this.way == 6) {
        this.way = '绿色积分+消费积分'
      }
    },
    methods: {
      ...mapMutations("goods", ["SET_RESET"]),
      onClickLeft() {
        this.$router.go(-1);
      },
      submitOrder() {
        if (this.addgrs == '') {
          Toast({
            message: '请设置默认地址',
            icon: 'fail',
            className: 'noticeWidth'
          });
        } else {
          orderPay({
            order_no: this.order_no,
            payment_method: JSON.parse(localStorage.getItem("wayts")),
            address_id: this.address_id
          }).then((res) => {
            if (res.data.code === 200) {
              this.$toast(res.data)
              return
            } else {
              Toast({
                message: res.data.message,
                icon: 'fail',
                className: 'noticeWidth'
              });
            }
            // document.querySelector('body').innerHTML = res;
            // document.forms[0].submit()
            // this.aliform = res
            // this.$nextTick(() => {
            //   this.getOrderDetail();
            //   document.forms[0].submit();
            // });
            // this.$router.push({ path: "/alipay", query: { htmls: res.data } })
            // if (res.code === 200) {
            //   Toast.success({
            //     message: res.message,
            //     className: 'noticeWidth',
            //   });
            //   setTimeout(() => {
            //     this.SET_RESET();
            //     this.$router.go(-1);
            //   }, 1500);
            // } else {
            //   Toast.fail({
            //     message: res.message,
            //     className: 'noticeWidth',
            //   });
            // }
          });
        }
      },
      // confirmPa() {
      //   checkPayPwdIsCorrect({
      //     payPassword: this.payPassword
      //   }).then(res => {
      //     if (res.code == 200) {
      //       this.submitOrder()
      //       setTimeout(() => {
      //         this.show2 = false
      //       }, 100)
      //     }
      //   })
      // },


      // 轮询结果
      // getOrderDetail() {
      //   //轮询方法,因为支付是跳转到第三方支付宝,我们无法获知用户是否支付成功,或者用户支付成功后是否跳转回来。轮询方法,在一定时间内
      //   clearTimeout(this.timer);
      //   this.timer = setTimeout(() => {
      //     let initTime = +new Date();
      //     let loop = () => {
      //       getOrderDetail({ orderCode: this.orderCode }).then((res) => {
      //         if (res.code == 20000 && res.data && res.data.payStatus == 30) {
      //           //支付成功的相关操作
      //         } else {
      //           let now = +new Date();
      //           if (now - initTime < 45000) {
      //             loop();
      //           } else {
      //             // 超时按照失败处理
      //             //支付失败的结果
      //           }
      //         }
      //       });
      //     };
      //     loop();
      //   }, 500);
      // },
      getMhList() {
        listShipAddress({}).then((res) => {
          // console.log(res);
          // editlist.value = res.data.list
          this.list = res.data.list.map(
            (item) => ({
              id: item.id,
              name: item.username,
              tel: item.mobile,
              address: item.merchant_address_sheng + item.merchant_address_shi + item.merchant_address_qu + item
                .merchant_address_dizhi,
              isDefault: item.address_default === 10 ? true : false,
            })
          )
          if (!JSON.parse(localStorage.getItem("stesadd"))) {
            this.addgrs = this.list[0].address
          } else {
            this.addgrs = JSON.parse(localStorage.getItem("stesadd")).address
          }
          // checked.value = list.value[0].id
          // console.log(this.list, 'aaa');
        })
      },
      chooseTime() {
        this.show = !this.show;
      },
      logNav(n) {
        this.nav = n;
      },
      logItem(n) {
        console.log(n);
        this.time = n.text;
      },
      ways() {
        this.$router.push({
          name: 'order'
        })
      }
    },
  };
</script>

<style lang="scss" scoped>
  .aliform {
    width: 1px;
    height: 1px;
    opacity: 0;
  }

  ::v-deep .van-toast {
    width: 150px !important;
  }

  ::v-deep .van-toast__text {
    font-size: 14px;
  }

  .order-detail {
    background: #f2f2f2;
    position: relative;

    .address {
      width: 710px;
      border-radius: 20px;
      background: #fff;
    }

    .content {
      width: 710px;
      height: 100%;
      border-radius: 20px;
      background: #fff;

      .van-card {
        padding: 20px;
        margin-bottom: 20px;
        border-radius: 10px;
        height: 220px;
        background-color: #fff;

        .van-card__title {
          font-size: 30px !important;
        }
      }

      .items {
        border-bottom: 1px solid #ddd;
        padding-bottom: 20px;

        .item {
          margin-bottom: 20px;

          .thr {
            text-decoration: line-through;
            color: #ddd;
            margin-top: 10px;
          }
        }
      }

      .rever {
        text-align: right !important;
      }
    }

    .phone,
    .dec {
      width: 710px;
      background-color: #fff;
      border-radius: 20px;
    }

    .dec {
      width: 710px;
      background-color: #fff;
      border-radius: 20px;
      padding: 30px 40px 20px 20px;
    }

    .in {
      height: 200px;
    }

    footer {
      position: fixed;
      bottom: 0;
      right: 0;
      height: 150px;
      width: 100%;
      padding: 0 30px;

      .van-button {
        width: 200px;
        padding: 30px;
        box-sizing: border-box;
      }

      .left {
        width: 490px;
        height: 120px;
        background-color: #000;
        border-radius: 60px 0 0 60px;
        padding: 25px 40px;
      }

      .right {
        width: 200px;
        height: 120px;
        background: linear-gradient(237.97deg,
            rgba(66, 148, 69, 1) 0%,
            rgba(128, 213, 111, 1) 100%);
        border-radius: 0 60px 60px 0;
        color: #fff;
      }
    }
  }

  ::v-deep .van-popup {
    padding: 20px 20px 20px 0;
  }

  ::v-deep .van-sidebar-item {
    height: 100px;
    text-align: center;
    line-height: 80px;
  }

  ::v-deep .van-tree-select__item {
    height: 80px !important;
  }
</style>
<style lang="scss">
  .noticeWidth {
    width: 30% !important;
    line-height: 35px;
    font-size: 32px !important;
  }
</style>
<style lang="scss">
  .bsbb {
    .van-ellipsis {
      overflow: visible;
      font-size: 32px;
    }
  }

  .noticeWidth {
    width: 30% !important;
    font-size: 32px !important;
  }

  .van-tree-select__selected {
    top: 0px !important;
    left: 40px !important;
  }

  .van-sidebar-item__text {
    font-size: 32px;
  }
</style>
